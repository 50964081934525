.certificate-item {
  border-radius: 40px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 250px;
  height: 170px;
  margin-right: 10px;
  font-size: 15px;
  cursor: pointer;
  transition: 450ms all;
  background: rgba(0,0,0,0.1);
  padding: 25px 15px 25px 15px;
  -webkit-transform-origin: center left;
          transform-origin: center left;
}
.certificate-item:hover{
  color: white;
}
.certificate-item:hover ~ .certificate-item {
  -webkit-transform: translate3d(25px, 0, 0);
          transform: translate3d(25px, 0, 0);
}

.certificate-item__img{
width: 150px;
height: 50px;
margin: 0;
-o-object-fit: cover;
  object-fit: cover;
}

.certificate-item__title{
padding: 5px;
}

.certificate-item__article{
display:flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
height:100%;
}

.certificate-item__imgTitle{
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
white-space: normal;
text-align: center;
}

.certificate-item__url button{
height: 30px;
width:30px;
border: 2px solid transparent;
border-radius: 10px;
display:flex;
align-items: center;
text-decoration: none;
background-color: rgb(138, 182, 88);
color: black;
padding: 2px;
margin: 0px;
}

.certificate-item__url button:hover{
cursor: pointer;
color: white;
}

.certificate-item__certificate{
width: 100%;
height: 100%;
}

.certificate-item__details {
display:flex;
flex-direction: row-reverse;
align-items: flex-end;
justify-content: space-between;
background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, transparent 60%);
bottom: 0;
font-size: 10px;
left: 0;
opacity: 0;
transition: 1000ms opacity;
padding: 10px;
position: absolute;
right: 0;
top: 0;
color: white;
}

.certificate-item:hover .certificate-item__details {
opacity: 1;
}
.certificate-item__details>h6 {
margin-left: 10px;
margin-bottom: 8px;
}
.certificate-item__details>div {
margin-right: 10px;
margin-bottom: 5px;
}



@media only screen and (max-width: 640px)
{
.certificate-item {
  cursor: pointer;
  transition: none;
  -webkit-transform-origin: none;
  transform-origin: none;
}
.certificate-item:hover ~ .certificate-item {
  -webkit-transform: none;
          transform: none;
}
.certificate-item__url button{
  cursor: pointer;
  color: white;
}
}