.contact{
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact__text-name{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
}
.contact__text-name>div>h3{
    text-align: center;
    font-weight: bold;
    padding-bottom: 10px;
    color: white;
}

.contact__text-nameH3>h3{
    padding-left: 5px;
}

.contact__text-email{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.contact__text-email>h3{
    text-align: center;
    font-weight: bold;
    padding-bottom: 10px;
    color: white;
}

.contact__text-email>h3>a{
    color: #00D8FF;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    padding-left: 5px;
}

.form-email{
    margin: 10px;
}

.form-email input{
    outline: none;
    width: 500px;
    height:35px;
    padding: 5px;
    border: 2px solid white;
    border-radius: 25px;
    color: black;
    background-color: transparent;
    font-weight: bold;
}

.form-email input::placeholder{
    color: white;
}
.form-email button{
    height: 35px;
    width:70px;
    border: 1px solid white;
    background-color: rgba(0,0,0,0.1);
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin-left: 10px;
}
.form-email button:hover{
    background-color: rgba(255,255,255,0.25);
}

@media only screen and (max-width: 950px)
{
    .form-email input{
        width: 250px;
    }

    .contact__text-name{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .contact__text-nameH3>h3{
        padding-left: 0px;
    }
}