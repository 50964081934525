.categories{
  display: flex;
  justify-items: right;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  padding: 20px 20px 0px 20px;
  background: linear-gradient(#1C3643,#98a0a8);
}
.isSkills{
  padding-top: 0;
  padding-left: 100px;
  background: #1C3643;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
}
.isAbout{
  padding-top: 0;
  padding-left: 100px;
  background: #1C3643;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
}

.isPortfolio1{
  background: #1C3643;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.isPortfolio2{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.isSearch{
  background: rgba(255,255,255,0.2);
  margin-top: 70px;
}
.isCertificate{
  background: #98a0a8;
  padding-bottom:20px;
  padding-left: 0;
  padding-right: 0;
}
.isContact{
  background:  #1C3643 ;
  /* border-top: 2px solid rgba(0,0,0,0.15); */
}
.categories__title {
    color: white;
    width: 100%;
    padding: 10px 20px 10px 20px;
}

@media only screen and (max-width: 640px)
{
  .categories{
    /* padding:0px; */
    display: flex;
    flex-direction: column;
  }
  .isAbout{
    padding-left: 20px;
  }
  .isSkills{
    padding-left: 20px;
  }
}