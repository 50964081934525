.portfolio2{
  margin: 0px;
  width: 300px;
  background-size: 100% 500px;
}

.project{
  background-color: rgba(255,255,255,0.2);
  border:1px solid transparent;
  border-radius: 10px;
  margin: 10px;
  margin-bottom: 15px;
  padding: 20px;
  display:grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 10px;
  width:280px;
  height:320px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.project-text{
  width: 95%;
  text-align: center;
  padding:10px;
  background-color:rgba(0,0,0,0.2);
  border-radius: 15px;
  color: white;
  font-size:90%;
  align-self: center;
  justify-self: center;
}

.project-image{
display:grid;
grid-template-columns: repeat(2, 1fr);
gap: 10px;
margin: 0;
}

.project-imageJob{
align-self: center;
justify-self: center;
width: 150px;
height:70px;
}

.project-imageCountry{
align-self: center;
justify-self: center;
width: 50px;
height: 50px;
border-radius: 50%;
box-shadow: 0px 5px 25px rgba(0,0,0,0.8);
}

.project-title{
color: white;
}

strong{ 
color: black;
}
.project-date_end{
text-align: left;
}

.project-date_start{
text-align: left;
}
.project-description{
text-align: left;
height:72px;
}

@media only screen and (max-width: 640px)
{
.portfolio{
  width: 300px;
}
}