@import "./Vars.scss";
@import "./Media.scss";

body {
  margin: 0;
  font-family: $theme-font;
  background: $main-color;
  
}
  
* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Fjalla One', sans-serif; 
  font-weight: normal;
  margin: 0px;
}

ul{
  margin: 0px;
}

h1 {
  margin: 0px;
  font-size: 50px;
  line-height: 1,5px; /*espaciado de línea*/
  letter-spacing: -0.2px;  /*espaciado de texto*/
  /*text-transform: uppercase; /*transformar texto texto*/
  /*text-decoration: dashed;  /*decorar de texto*/
}

h2{
  color: white;
  text-transform: uppercase;
  padding: 10px;
  font-family: 'Source Sans Pro',sans-serif;
  font-weight: bold;    
}

figure{
  margin: 0;
}