.portfolio1{
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin:15px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

}
.portfolio1__link{
  text-decoration:none;
  width: 500px;
}

.portfolio1__link-details{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: linear-gradient(to top,rgba(0, 0, 0, 0.8) 0%, transparent 100%);
}

.portfolio1__link-details>h5 {
 color: white;
}
.portfolio1__link-details>img {
  width: 20px;
  margin-right: 10px;
}


@media only screen and (max-width: 1000px)
{
.portfolio1{
  width: 300px;
}
.portfolio1__link-img>img{
  width:300px;
}
.portfolio1__link-details{
  padding: 5px;
}
.portfolio1__link-details {
  opacity: 1;
  }
  .portfolio1__link-details>h5 {
    font-size: 0.8em;
     }
}