.home {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 200px); /* El ancho será igual al tamaño de todo el height menos 200px (100px del header + 100px del footer) */
}

.home__portfolio1{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-self: center;
  justify-self: center;
  gap: 10px;
}

.home__about{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-self: start;
  justify-self: start;
  gap: 10px;
  margin-bottom: 20px;
}

.home__skills{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-self: start;
  justify-self: start;
  gap: 10px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px)
{
  .home__portfolio1{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-self: center;
    justify-self: center;
    gap: 10px;
  }
}