.header{
    align-items: center;
    color: white;
    display: flex;
    height: 70px;
    justify-content: space-between;
    margin: 0px;
    width: 100%;
    background-color: #1C3643;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    padding-right: 20px;


}

.header__logo {
    margin-left: 30px;
    width: 250px;
    height:50px;
}

.header>nav{
    display: flex;
}

.header ul{
    display: flex;
    align-items: left;
}


.header a{
    color: white;
    text-decoration: none;
    height: inherit;
    display: flex;
    align-items: center;
    padding: 0 20px;
}


  
.header__menu {

height: 80px;
display: flex;
justify-content: flex-end;
}
  
.header__menu ul {
display: none;
list-style: none;
padding: 0px;
position: absolute;
width: 180px;
margin: 70px 0px 0px 0px;
border-radius: 10px;
background-color: white;
text-align: left;
z-index: 1;
}
  
.header__menu:hover ul, ul:hover {
display: block;
}

  
.header__menu li a {
color: black;
font-weight: bold;
text-decoration: none;
padding: 10px 0px 10px 10px;
}

.header__menu li a:hover {
background-color: rgba(0,0,0,0.5);
color: white;
border-radius: 10px;
}
  
.header__menu--profile {
    height:70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    text-align: left;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 10px;
}
  
.header__menu--profileImg {
    width: 40px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    text-align: left;

}

.header__menu--profileImg:hover {
    color: #00D8FF;
}
  
.header__menu--download {
    height:70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    text-align: left;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 10px;
}
.header__menu--download a{
    padding:0px;
}
    
.header__menu--downloadImg {
    width: 40px;
    height:40px;
    color: white;
}
.header__menu--downloadImg:hover {
    color: #FF6347;
}

@media only screen and (max-width: 950px)
{
    .header__logo {
        margin-left: 15px;
        width: 220px;
      }
}