.Skills{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Skills__container-text>p{
    padding-left: 20px;
    width: 70%;
    color:white ;
    text-align: start;
}

.Skills__container-bar{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 0;
}

.Skills__container-bar1{
    justify-items: center;
}
.Skills__container-bar1>ul{
    padding: 0;
    .li{
        .h5{
            color: white;
        }
    }
}
.Skills__container-bar1>ul>li>h5{
    color: white;
    text-align: center;
    margin-top: 5px;
}


.Skills__container-bar1{
    justify-self: center;
}


.Skills__container-bar1>ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Skills__container-bar1>ul>li{
    margin: 0;
}

.Skills__container-barImg{
    color:white;
    margin: 20px 20px 0 20px;
    width: 100px;
    height: 80px;

}

.Skills__container-barImg:hover{
    filter: opacity(.5);
}

#reactLogo:hover{
    color: #00D8FF ;
}

@keyframes spinHorizontal {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(180deg); }
}


@media only screen and (max-width: 640px)
{
    .Skills__container-text>p{
        width: 90%;
    }
    .Skills__container-bar1>ul{
        padding: 0;
    }

}
@media only screen and (max-width: 450px)
{
    .Skills__container-barImg{
        margin: 10px 10px 0 10px;
        width: 60px;
        height: 50px;
    }
}
