.footer{
    color: white;
    background-color: #1C3643;
    /* display: flex;
    flex-direction: column; */
    align-items: center;  
    justify-content: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
}

.footer__text{
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    /* font-size:2px; */
}
.footer__text>h5>strong{
    padding-left: 2px;
    padding-right: 5px;
    color: #FF6347;
}

.footer__img{
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* background-color: rgba(255,255,255,0.25); */
}

.footer img{
    height: 40px;
    width: 40px;
    margin: 10px 20px 10px 20px;
}

.footer img:hover{
   transform: scale(1.4,1.4);
}

@media only screen and (max-width: 640px)
{
    .footer__img {
        flex-direction: column;
    }
}