.hero{
    padding:20px;
    background-size: 100% 500px;
    color: white;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    flex-shrink: 0;
    background:url(../../static/stars.svg),#1C3643;
    margin-top: 70px;
}

.hero__text {
    width: 530px;
	margin: auto;
	display: table;
    align-self: center;
    justify-self: center;
    padding:10px;

}

.hero__text-name{ 
	position: relative; 
	float: left;
	background:transparent;
	color: white;
	font-size: 3em;
    white-space: nowrap;
}

.hero__text-front{ 
    position: relative; 
    float: left;
    color: white;
    font-size: 2.5em;
    padding:0px;
}

.hero__text-ing{ 
    position: relative; 
    float: left;
    color: white;
    font-size: 2.5em;
    padding:0px;
    white-space: nowrap;
}
.hero__text-ing span {
    position:absolute;
    right:0;
    width:0;
    border-left: 4px solid white;
    animation:blinkTextCursor 500ms steps(28) infinite normal;
}

@keyframes escribir {
    from { width: 100% }
    to { width:0 }
}

@keyframes blinkTextCursor {
    from {
          border-left-color: white;
    }
    to {
          border-left-color: transparent;
    }
}

.hero h1 strong{
    color: #FF6347;
}

.hero__figure{
    width: 280px;
    align-self: center;
    justify-self: center;
}

.hero img{
    margin:20px;
    width: 250px;
    border: 2px solid none;
    background-color: white;
    border-radius: 15px;
    padding:10px;
}

@media only screen and (max-width: 950px)
{
    .hero{
        width: 100%;
        padding:20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero__text {
        width: 320px;
    }
    .hero__text-front{ 
        font-size: 1.5em;
    }
    .hero__text-name{ 
        font-size: 1.5em;
    }
    .hero__text-ing{ 
        font-size: 1.5em;
    }
    .hero__figure{
        width: 200px;
        display:flex;
        justify-content: center;
    }

    .hero img{
        width: 200px;
   }
}