@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.text {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Press Start 2P';
    color: #FFFFFF;
    text-align: center;
}

section.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5%;
    height: 100vh;
}

section.notFound h1 {
    color: red;
    font-size: 100px;
}

section.notFound h2 {
    font-size: 50px;
}

div.text {
    height: 50vh;
}

div.text a {
    text-decoration: none;
    margin-right: 20px;
}

div.text a:hover {
    color: red;
    text-decoration: underline; 
}

@media only screen and (max-width: 640px) {
    section.notFound {
        flex-direction: column;
        justify-content: space-around;
    }
    section.notFound div.img img {
        width: 70vw;
        height: auto;
    }
    section.notFound h1 {
        font-size: 50px;
    }
    section.notFound h2 {
        font-size: 25px;
    }
    div.text a:active {
    color: red;
    text-decoration: underline; 
  }
}