.About{
    padding-left: 20px;
    display: flex;

}

.About__container-text>p{
    width: 70%;
    color:white ;
    text-align: start;
}

@media only screen and (max-width: 640px)
{
    .About__container-text>p{
        width: 90%;
    }
}