.certificate {
  overflow-x: scroll;
  width: 100%;
  position: relative;
  margin: 0;
}

.certificate__container {
transition: 450ms -webkit-transform;
transition: 450ms transform;
transition: 450ms transform, 450ms -webkit-transform;
font-size: 0;
white-space: nowrap;
padding:10px 10px 20px 10px;
}

.certificate__container:hover .certificate-item {
opacity: 0.3;
}

.certificate__container:hover .certificate-item:hover {
-webkit-transform: scale(1.1);
        transform: scale(1.1);
opacity: 1;
}

.certificate::-webkit-scrollbar{
height:10px;
}
.certificate:hover::-webkit-scrollbar{
background-color: rgba(255,255,255,0.5);
border-radius: 15px;
}
.certificate:hover::-webkit-scrollbar-track{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: rgba(0,0,0,0.1);
border-radius: 15px;
}
.certificate:hover::-webkit-scrollbar-thumb{
background-color: rgba(0,0,0,0.5); 
background-image: -webkit-linear-gradient(90deg,rgba(0,0,0,0.2) 75%,transparent 75%,transparent);
border-radius: 15px;
}

@media only screen and (max-width: 640px)
{
.certificate__container {
  padding:10px 10px 10px 10px;
}
.certificate__container:hover .certificate-item:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.certificate::-webkit-scrollbar{
  background-color: rgba(255,255,255,0.5);
  border-radius: 15px;
}
.certificate::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: rgba(0,0,0,0.1);
  border-radius: 15px;
}
.certificate::-webkit-scrollbar-thumb{
  background-color: rgba(0,0,0,0.5); 
  background-image: -webkit-linear-gradient(90deg,rgba(0,0,0,0.2) 75%,transparent 75%,transparent);
  border-radius: 15px;
}
}